@import (reference) '../../style/global.less';

.question {
    border-top: solid 1px @alude-gray-4;

    & ul {
        margin: 0;
    }
}

.orange {
    & svg {
        color: @alude-red-orange;
    }
}

.gray {
    & svg {
        color: @alude-gray-6;
    }
}

.iconLabel {
    & p {
        flex-shrink: 1;
    }
}
