@import (reference) 'antd/lib/style/themes/index.less';
@import (reference) 'antd/lib/tag/style/index.less';
@import (reference) '../style/ant-overrides.less';

.make-status-color-classes(@baseColor, @bgIndex, @status) {
    @background: '@{baseColor}-@{bgIndex}';
    @color: '@{baseColor}-9';

    &-@{status} {
        background: @@background;
        color: @@color;
    }
}

@tag-prefix-cls: ~'ald-sub-tag';

.@{tag-prefix-cls} {
    background: @alude-minsk-1;
    border: none;
    font-size: @tag-font-size - 2px;
    font-weight: .semibold-alude[font-weight];
    height: @tag-line-height - 6px;
    line-height: 1.75em;
    margin-right: 0;
    margin-left: 4px;
    padding: 0 4px;

    .make-status-color-classes('alude-green', 3, success);
    .make-status-color-classes('alude-blue', 3, processing);
    .make-status-color-classes('alude-red', 3, error);
    .make-status-color-classes('alude-orange', 3, warning);
}

.ald-sub-tag {
    .ald-sub-tag();
}
